(($) => {
    $(document).on('turbolinks:load', () => {
        const video = document.getElementById('homepage-video');
        const playlist = document.querySelectorAll('.video-playlist > .playlist-item');
        const volumeToggle = document.getElementById('homepage-video-toggle-volume');
        const playToggle = document.getElementById('homepage-video-play-pause');

        volumeToggle.addEventListener('click', (e) => {
            e.preventDefault();
            video.muted = !video.muted;
            if (video.muted) {
                volumeToggle.children[0].classList.add('fa-volume-off');
                volumeToggle.children[0].classList.remove('fa-volume-up');
            } else {
                volumeToggle.children[0].classList.remove('fa-volume-off');
                volumeToggle.children[0].classList.add('fa-volume-up');
            }
        });

        playToggle.addEventListener('click', (e) => {
            e.preventDefault();
            if (video.paused) {
                video.play();
                playToggle.children[0].classList.add('fa-pause');
                playToggle.children[0].classList.remove('fa-play');
            } else {
                video.pause();
                playToggle.children[0].classList.remove('fa-pause');
                playToggle.children[0].classList.add('fa-play');
            }
        });

        for (const item of playlist) {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                const currentItem = Array.prototype.slice.call(playlist).find(o => o.classList.contains('current'));
                currentItem.classList.remove('current');
                item.classList.add('current');
                video.removeAttribute('poster');
                video.src = item.href;
                // this will cause the video to start playing
                playToggle.children[0].classList.remove('fa-play');
                playToggle.children[0].classList.add('fa-pause');
            });
        }

        video.addEventListener('ended', () => {
            const currentIndex = Array.prototype.slice.call(playlist).findIndex(item => item.classList.contains('current'));
            const nextIndex = (currentIndex + 1) % playlist.length;
            playlist[currentIndex].classList.remove('current');
            playlist[nextIndex].classList.add('current');
            video.src = playlist[nextIndex].href;
        });
    });
})(jQuery);
